import * as React from "react"
import { Formik } from "formik"
import newsicon from "../../assets/images/icons/newsicon.svg"
import addToMailchimp from "gatsby-plugin-mailchimp"
import Swal from "sweetalert2"

const NewsLetter = ({ pageContext: { translate } }) => {
  const sendSubscribe = (
    values,
    setSubmitting,
    setFieldValue,
    setFieldTouched
  ) => {
    addToMailchimp(values.email, {
      MERGE6: "sewnstaging",
    })
      .then(data => {
        if (data.result === "success") {
          Swal.fire({
            icon: "success",
            text: translate.t?.subscribed_successfully,
            confirmButtonColor: "#30855c",
            timer: 3000,
          })
        } else if (
          data.result === "error" &&
          data.msg?.includes("already subscribed")
        ) {
          Swal.fire({
            icon: "error",
            text: translate.t?.already_subscribed,
            confirmButtonColor: "#30855c",
            timer: 3000,
          })
        } else {
          Swal.fire({
            icon: "error",
            text: translate.t?.common_error,
            confirmButtonColor: "#30855c",
            timer: 3000,
          })
        }
        setSubmitting(false)
        setFieldValue("email", "")
        setFieldTouched("email", false)
      })
      .catch(() => {
        Swal.fire({
          icon: "error",
          text: translate.t?.common_error,
          confirmButtonColor: "#30855c",
          timer: 3000,
        })
        setSubmitting(false)
      })
  }

  return (
    <>
      {
        <div className="newsletter">
          <div className="content">
            <h2>{translate.t?.subscribe_to_our_newsletter}</h2>
            <h3>{translate.t?.keep_up_to_date_news}</h3>
            <Formik
              initialValues={{ email: "" }}
              validate={values => {
                const errors = {}
                if (!values.email) {
                  errors.email = translate.t?.required
                } else if (
                  !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                ) {
                  errors.email = "Invalid email address"
                }
                return errors
              }}
              onSubmit={(
                values,
                { setSubmitting, setFieldValue, setFieldTouched }
              ) => {
                sendSubscribe(
                  values,
                  setSubmitting,
                  setFieldValue,
                  setFieldTouched
                )
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                /* and other goodies */
              }) => (
                <form onSubmit={handleSubmit}>
                  <div className="form-group">
                    <input
                      type="email"
                      name="email"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email}
                      placeholder={translate.t?.email_address}
                    />
                    <div className="error">
                      {errors.email && touched.email && errors.email}
                    </div>
                  </div>
                  <button type="submit" disabled={isSubmitting}>
                    <img src={newsicon} alt="News icon" />
                  </button>
                </form>
              )}
            </Formik>
          </div>
        </div>
      }
    </>
  )
}

export default NewsLetter
