import React, { useEffect } from "react"
import Blog from "./blogs"
import NewsLetter from "./newsletter"
// import downicon from "../../assets/images/icons/down-icon.svg"
// import { Dropdown } from "react-bootstrap"
import { useStaticQuery, graphql } from "gatsby"

const Home = ({ pageContext: { translate } }) => {
  // const [cat, setCat] = React.useState([])
  // const [catSelect, setcatSelect] = React.useState("All Types")

  const nodes = useStaticQuery(
    graphql`
      query MyQuery {
        allMarkdownRemark {
          nodes {
            frontmatter {
              author_image
              author_name
              blog_image
              blog_post
              category
              date
              slug
              title
              page_title
              meta_description
              thumbnail_summary
              thumbnail_image
              feature1
              feature2
            }
          }
        }
      }
    `
  )

  const allData = nodes.allMarkdownRemark.nodes

  useEffect(() => {
    const allCat = []
    for (const value of allData) {
      if (!allCat.includes(value.frontmatter.category)) {
        allCat.push(value.frontmatter.category)
      }
    }
    // setCat(allCat)
  }, [allData])
  return (
    <div className="community_page">
      <div className="content">
        <div className="feature">
          <h1 className="mb-3 mb-lg-5">{translate.t?.coffee_community_news}</h1>
          <div className="super">
            {allData.map(
              (value, counter) =>
                !!value.frontmatter?.feature1 && (
                  <Blog
                    pageContext={{ translate }}
                    key={counter}
                    imgsec={value.frontmatter.thumbnail_image}
                    mobileimg={value.frontmatter.thumbnail_image}
                    len={85}
                    type={value.frontmatter.category}
                    heading={value.frontmatter.title}
                    description={value.frontmatter.thumbnail_summary}
                  />
                )
            )}
          </div>
        </div>
        <NewsLetter pageContext={{ translate }} />
        <div className="feature">
          <div className="super-non">
            {allData.map(
              (value, counter) =>
                !!value.frontmatter?.feature2 && (
                  <div key={counter} className="blog-wrapper col-6">
                    <Blog
                      pageContext={{ translate }}
                      imgsec={value.frontmatter.thumbnail_image}
                      mobileimg={value.frontmatter.thumbnail_image}
                      len={65}
                      type={value.frontmatter.category}
                      heading={value.frontmatter.title}
                    />
                  </div>
                )
            )}
          </div>
        </div>
        {/* <div className="configuration">
          <div className="custom-drop">
            <Dropdown>
              <Dropdown.Toggle id="dropdown-basic">
                {catSelect}
                <img src={downicon} alt="Down" />
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item
                  onClick={() => {
                    setcatSelect("All Types")
                  }}
                >
                  All Types
                </Dropdown.Item>

                {cat?.map((val, counter) => (
                  <Dropdown.Item
                    key={counter}
                    onClick={() => {
                      setcatSelect(val)
                    }}
                  >
                    {val}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div> */}
        {/* <div className="all-list">
          {allData.map((value, count) => {
            if (
              catSelect === value.frontmatter.category &&
              !value.frontmatter.feature1 &&
              !value.frontmatter.feature2
            ) {
              return (
                <div
                  key={count}
                  className="col-12 col-md-6 col-lg-4 blog-wrapper"
                >
                  <Blog
                    imgsec={value.frontmatter.thumbnail_image}
                    mobileimg={value.frontmatter.thumbnail_image}
                    len={65}
                    type={value.frontmatter.category}
                    heading={value.frontmatter.title}
                  />
                </div>
              )
            } else if (
              catSelect === "All Types" &&
              !value.frontmatter.feature1 &&
              !value.frontmatter.feature2
            ) {
              return (
                <div
                  key={count}
                  className="col-12 col-md-6 col-lg-4 blog-wrapper"
                >
                  <Blog
                    imgsec={value.frontmatter.thumbnail_image}
                    mobileimg={value.frontmatter.thumbnail_image}
                    len={65}
                    type={value.frontmatter.category}
                    heading={value.frontmatter.title}
                  />
                </div>
              )
            } else {
              return null
            }
          })}
        </div> */}
      </div>
    </div>
  )
}

export default Home
