import { Link } from "gatsby"
import * as React from "react"

const SingleBlog = ({
  len,
  imgsec,
  type,
  date,
  heading,
  mobileimg,
  description,
  pageContext: { translate },
}) => {
  const assetLoader = require.context("../../../static/assets", true)
  const imageLoader = imgPath => assetLoader(`./${imgPath}`).default

  var g = new Date(date)
  return (
    <Link
      to={`${
        translate.currentLang ? "/" + translate.currentLang + "/" : "/"
      }community-news/${heading
        .replace(/\s/g, "-")
        .replace(/[^\w-]/g, "")}/`.toLowerCase()}
    >
      <div className="singleBlog">
        <div className="box ">
          {imgsec && (
            <img
              className="desktop"
              src={imageLoader(
                imgsec?.split("/")[imgsec?.split("/").length - 1]
              )}
              alt="Single Blog"
            />
          )}

          <div className="define">
            <h3> {translate.t?.article} </h3>
            <h4>{heading}</h4>
            {date && <p>{g.toISOString().slice(0, 10)}</p>}
            {description && <p>{description?.slice(0, len)}...</p>}
          </div>
        </div>
      </div>
    </Link>
  )
}

export default SingleBlog
